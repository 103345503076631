
              @import "@/assets/css/variables.scss";
            























































































































































.trading_tool_mt4 {

  .sec1 {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 25%;
    background: url('~@/assets/images-1/tradingTool/mt4_sec1_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    color: $white;
    border-radius: 8px 8px 0 0;

    @include screen-mobile {
      height: auto;
      padding: 32px 40px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 12%;
    }

    .title {
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 16px;

      @include screen-mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .word {
      font-size: 16px;
      line-height: 24px;

      @include screen-mobile {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .line {
      width: 74px;
      height: 1px;
      background: $white;
      margin-top: 24px;

      @include screen-mobile {
        width: 24px;
        margin-top: 16px;
      }
    }
  }

  .sec2 {
    display: flex;

    .download {
      position: relative;
      background: $secondary;
      width: 25%;
      padding: 44px 24px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include screen-mobile {
        padding: 16px 24px;
      }

      .icon {
        width: 40px;
        height: 40px;
      }

      &:nth-child(1) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/mac.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(2) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/windows.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(3) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/ios.png') no-repeat;
          background-size: 100%;
        }
      }

      &:nth-child(4) {
        .icon {
          background: url('~@/assets/images-1/tradingTool/android.png') no-repeat;
          background-size: 100%;
        }
      }

      p {
        font-size: 16px;
        color: $white;
        line-height: 24px;
        margin-top: 26px;

        @include screen-mobile {
          margin-top: 16px;

          span {
            display: none;
          }
        }
      }

      &+.download {
        &::before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          width: 1px;
          height: 40px;
          background: $white;
        }
      }

      &:hover {
        background: $white;
        cursor: pointer;

        .icon {
          background: url('~@/assets/images-1/tradingTool/download.png') no-repeat;
          background-size: 100%;
        }

        p {
          color: $text-primary;
        }
      }


      &:nth-child(-n + 3) {
        &:hover {
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -1px;
            width: 1px;
            height: 100%;
            background: $white;
            z-index: 1;
          }
        }
      }
    }

  }

  .sec3 {
    background: $white;
    padding: 0 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 0 24px;
    }



    .cont_left {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        @include screen-mobile {
          margin-bottom: 16px;
        }

        @media (min-width: 781px) and (max-width: 1200px) {
          margin-bottom: 24px;
        }

        img {
          width: 46px;
          height: 43px;
          @include rtl-sass-prop(margin-right, margin-left, 16px);

          @include screen-mobile {
            width: 32px;
            height: 32px;
          }

        }

        span {
          font-size: 32px;
          color: $text-title;
          line-height: 48px;

          @media (max-width: 1200px) {
            font-size: 24px;
            line-height: 36px;
          }
        }

      }

      .desc {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        @include rtl-sass-prop-dual(text-align, left, text-align, right);

      }
    }

    .center_img {
      width: 290px;
      height: 298px;
      margin: 60px 24px 0;

      @include screen-mobile {
        width: 156px;
        height: 160px;
        margin: 16px 0;
      }

      @media (min-width: 781px) and (max-width: 1200px) {
        width: 220px;
        height: 220px;
      }
    }

    .cont_right {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      p {
        font-size: 14px;
        color: $text-primary;
        line-height: 22px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 22px;
        @include rtl-sass-prop(padding-left, padding-right, 22px);

        span {
          font-size: 24px;
          color: $text-primary;
          line-height: 22px;
          font-weight: 700;
          margin: 0 2px;
        }

        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 3px;
          @include rtl-sass-prop(left, right, 0);
          bottom: 0;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images-1/tradingTool/icon_blue_check.png') no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }

      p:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }

  .sec4 {
    background: $secondary;
    padding: 60px 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    @media (min-width: 781px) and (max-width: 1200px) {
      padding: 60px 24px;
    }

    .cont_left {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      .title {
        font-size: 32px;
        color: $white;
        line-height: 48px;
        margin-bottom: 40px;

        @include screen-mobile {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
          @include rtl-sass-prop-dual(text-align, left, text-align, right);
        }

        @media (min-width: 781px) and (max-width: 1200px) {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 24px;
        }
      }

      .desc {
        font-size: 14px;
        color: $white;
        line-height: 22px;

        @include screen-mobile {
          @include rtl-sass-prop-dual(text-align, left, text-align, right);
        }
      }
    }

    img {
      width: 190px;
      height: 238px;
      margin: 0 74px;

      @include screen-mobile {
        width: 128px;
        height: 160px;
        margin: 16px 0;
      }

      @media (min-width: 781px) and (max-width: 1200px) {
        width: 128px;
        height: 160px;
        margin: 0 32px;
      }
    }

    .cont_right {
      width: 366px;

      @include screen-mobile {
        width: 100%;
      }

      p {
        font-size: 14px;
        color: $white;
        line-height: 22px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 22px;
        @include rtl-sass-prop(padding-left, padding-right, 22px);

        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 3px;
          @include rtl-sass-prop(left, right, 0);
          bottom: 0;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images-1/tradingTool/icon_blue_check.png') no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }

      p:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }

  .sec5 {
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 30px 60px 48px;

    @include screen-mobile {
      flex-direction: column;
      padding: 32px 24px;
    }

    .cont_left {
      .title {
        font-size: 32px;
        color: $text-title;
        line-height: 48px;
        font-weight: 600;
        margin-bottom: 40px;

        @include screen-mobile {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
        }
      }

      .word {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        margin-bottom: 16px;

        @include screen-mobile {
          font-size: 16px;
          line-height: 24px;
        }
      }

      img {
        display: none;
        width: 286px;
        height: 160px;
        @include rtl-sass-prop(margin-left, margin-right, 50%);
        @include rtl-sass-prop-dual(transform, translateX(-50%), transform, translateX(50%));

        @include screen-mobile {
          display: block;
        }
      }

      .download {
        display: flex;
        flex-wrap: wrap;

        >div {
          width: 50%;
          display: flex;
          align-items: center;
          margin-top: 24px;
          cursor: pointer;

          @include screen-mobile {
            width: 100%;

            &:nth-child(1) {
              margin-top: 16px;
            }
          }

          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $secondary;
            flex: none;
            text-align: center;
            padding-top: 8px;
            @include rtl-sass-prop(margin-right, margin-left, 16px);

            svg {
              font-size: 24px;
            }

          }

          span {
            font-size: 14px;
            color: $text-primary;
            line-height: 22px;
          }

          &:hover {
            .icon {
              background: $primary;
            }

            span {
              color: $primary;
            }
          }
        }
      }
    }

    >img {
      @include screen-mobile {
        display: none;
      }

      @media (min-width: 781px) and (max-width: 992px) {
        width: 286px;
        height: 160px;
      }

      @media (min-width: 993px) and (max-width: 1200px) {
        width: 388px;
        height: 216px;
      }
    }
  }

  .sec6 {
    background: $secondary;
    padding: 60px 48px;
    border-radius: 0 0 8px 8px;

    @include screen-mobile {
      padding: 32px 24px;
    }

    .title {
      font-size: 32px;
      color: $white;
      line-height: 48px;
      font-weight: 600;
      margin-bottom: 24px;
      text-align: center;

      @include screen-mobile {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
    }

    .cont {
      display: flex;
      justify-content: space-between;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      >div {
        width: 33.33%;
        background: rgba(255, 255, 255, .2);
        border-radius: 16px;
        padding: 40px 16px;
        position: relative;
        text-align: center;
        color: $white;
        @include rtl-sass-prop(margin-right, margin-left, 16px);


        @media (max-width: 992px) {
          width: 100%;
          margin: 0 0 16px 0;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        .num {
          position: absolute;
          top: 8px;
          left: 8px;
        }

        .num1 {
          width: 86px;
          height: 100px;

          @include screen-mobile {
            width: 60px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 60px;
            height: 70px;
          }

        }

        .num2 {
          width: 101px;
          height: 100px;

          @include screen-mobile {
            width: 70px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 70px;
            height: 70px;
          }
        }

        .num3 {
          width: 102px;
          height: 100px;

          @include screen-mobile {
            width: 71px;
            height: 70px;
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            width: 71px;
            height: 70px;
          }
        }

        .secondary_title {
          font-size: 18px;
          line-height: 28px;
          font-weight: 700;
          margin: 24px 0 8px;
        }

        .word {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }


  }


}
